import { tw } from '@/utils/tw';
import BaseImage from 'base/components/standalone/Image/theme';

const Image = tw.theme({
  extend: BaseImage,
  slots: {
    caption: 'flex-col items-start gap-1.5 text-black text-body-sm',
    byline: 'text-gray-600 text-body-sm',
  },
});

export default Image;
